<template>
  <v-container fluid>
    <BreadHeader :title="model.add.name">
      <v-btn color="secondary" @click="redirectBack()">Back </v-btn>
    </BreadHeader>

    <transition name="fade">
      <v-alert
        dense
        border="left"
        type="warning"
        class="text-pre-wrap"
        v-if="alertError.length > 0"
        ><span
          class="d-block"
          v-for="(error, index) in alertError"
          :key="index"
          >{{ error }}</span
        ></v-alert
      >
    </transition>

    <v-card>
      <v-toolbar flat :color="HRDC_BLUE_THEME_COLOR" dark>
        <v-toolbar-title>{{ model.view.name }} Setting</v-toolbar-title>
      </v-toolbar>
      <v-tabs vertical>
        <v-tab>
          <v-icon left> mdi-calendar-star </v-icon>
          {{ model.view.name }}
        </v-tab>
        <v-tab>
          <v-icon left> mdi-format-list-bulleted-square </v-icon>
          Event Category
        </v-tab>

        <v-tab-item>
          <v-card flat>
            <component
              :is="eventTab"
              :params="params"
              @toggleEventPage="toggleEventPage"
            ></component>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <component
              :is="eventCategoryTab"
              :params="params"
              @toggleEventCategoryPage="toggleEventCategoryPage"
            ></component>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <HrdcLoader :loadingDialog="loadingDialog"></HrdcLoader>
  </v-container>
</template>

<script>
import Api from "@/objects/api";
import { mapState } from "vuex";
import EventAdd from "./event/Add";
import Model from "@/objects/model";
import EventIndex from "./event/Index";
import Service from "@/objects/service";
import hrdcFunction from "@/objects/hrdcFunction";
import EventCategoryAdd from "./event_category/Add";
import EventCategoryIndex from "./event_category/Index";
import HrdcData from "@/services/hrdc_einvoicing/objects/globalData";

export default {
  mixins: [hrdcFunction],
  components: {
    EventIndex,
    EventAdd,
    EventCategoryIndex,
    EventCategoryAdd,
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: ["params"],
  data: () => ({
    apiSubmit: new Api(),
    model: new Model(),
    service: new Service(),
    hrdcData: new HrdcData(),
    alertError: [],
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    formData: {
      applicationNo: null,
    },
    errorField: {
      applicationNo: null,
    },
    loadingDialog: false,
    btnDisabled: false,
    eventTab: "EventIndex",
    eventCategoryTab: "EventCategoryIndex",
  }),
  async created() {
    this.showLoadingDialog();
    this.model.getByKey(this.params.modelKey);
    if (this.model.serviceKey) this.service.set("key", this.model.serviceKey);

    // Assign apiDetails by mapping repository.
    const apiDetails = {
      apiSubmit: {
        url: `${
          this.$service[this.service.key]
        }/v1/en/console/claim_approved/update-claim-approved`,
        method: "post",
      },
    };

    for (const api in apiDetails) {
      if (apiDetails[api].url !== undefined) {
        this[api].setUrl(apiDetails[api].url);
      }
      if (apiDetails[api].method !== undefined) {
        this[api].setMethod(apiDetails[api].method);
      }
      if (apiDetails[api].params !== undefined) {
        this[api].setParams(apiDetails[api].params);
      }
    }

    this.stages = await this.hrdcData.allStages;
    this.rolesAssignation(this.auth.roles);

    this.hideLoadingDialog();
  },
  methods: {
    clearErrorMsg() {
      for (let error in this.errorField) {
        this.errorField[error] = null;
      }
    },
    showErrorMessage(response) {
      for (const key in response.data) {
        this.errorField[key] = null;
        this.errorField[key] = response.data[key];
      }
    },
    redirectBack() {
      let route = "HrdcEinvoicingSettings";
      if (this.params.backTo) {
        route = this.params.backTo;
      }

      this.$router.push({
        name: route,
        params: {
          modelKey: this.model.key,
          backTo: "",
        },
      });
    },
    toggleEventPage(redirectToComponent) {
      this.eventTab = redirectToComponent;
    },
    toggleEventCategoryPage(redirectToComponent) {
      this.eventCategoryTab = redirectToComponent;
    },
    async submit() {
      this.loadingDialog = !this.loadingDialog;
      let data = {
        applicationUuid: this.formData.applicationNo,
        claimApprovedDate: this.formData.claimApprovedDate,
        referById: this.auth.uuid,
        editMode: this.editMode,
      };

      this.apiSubmit.setParams(data);
      this.apiSubmit.setCallbackCompleted((response) => {
        try {
          this.clearErrorMsg();
          this.loadingDialog = !this.loadingDialog;
          if (!response.status) {
            this.showErrorMessage(response);
            this.$store.dispatch("showMessage", {
              message: response.message,
              messageType: "error",
              timeout: 2000,
            });
          }

          if (response.status) {
            this.$store.commit("assignDeveloperData", {
              paginationPageNo: this.$route.query.currentPageNo,
            });

            this.$router.push({
              name: "HrdcEinvoicingApplication",
              query: {
                viewId: this.$store.state.hrdc_einvoicing.data.viewId,
              },
            });
            this.$store.dispatch("showMessage", {
              message: response.data,
              messageType: "success",
              timeout: 2000,
            });
          }
        } catch (err) {
          console.log(err);
        }
      });
      this.apiSubmit.fetch();
    },
  },
};
</script>

<style>
.v-slide-group__content {
  border-right: 1px solid #e8e8e8;
}
</style>
