var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('BreadHeader',{attrs:{"title":_vm.model.view.name}},[_c('v-btn',{staticClass:"white--text",attrs:{"color":_vm.HRDC_BLUE_THEME_COLOR},on:{"click":function($event){return _vm.toggleEventPage()}}},[_c('v-icon',[_vm._v(" mdi-plus ")])],1)],1),_c('AError',{attrs:{"api":_vm.apiDelEvent}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.alertError.length > 0)?_c('v-alert',{staticClass:"text-pre-wrap",attrs:{"dense":"","border":"left","type":"warning"}},_vm._l((_vm.alertError),function(error,index){return _c('span',{key:index,staticClass:"d-block"},[_vm._v(_vm._s(error))])}),0):_vm._e()],1),_c('v-card',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.eventData,"items-per-page":10},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getStatusColor(item.status)}},[_vm._v(" "+_vm._s(_vm.getStatusIcon(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.HRDC_BLUE_THEME_COLOR,"dark":""}},[_vm._v(" Delete "+_vm._s(_vm.model.name.plural)+" ")]),_c('div',{staticClass:"pa-4"},[_vm._v("Are you sure you want to delete this item ?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-light",attrs:{"color":"green lighten-1"},on:{"click":_vm.closeDelete}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"text-light",attrs:{"color":"red lighten-1"},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v(" Delete ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.HRDC_BLUE_THEME_COLOR,"dark":""}},[_vm._v(" Edit "+_vm._s(_vm.model.name.singular)+" ")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pb-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-text-field',{attrs:{"error-messages":_vm.errorField.title,"dense":"","filled":"","label":"Title"},model:{value:(_vm.editedItem.title),callback:function ($$v) {_vm.$set(_vm.editedItem, "title", $$v)},expression:"editedItem.title"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"return-value":_vm.date1,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date1=$event},"update:return-value":function($event){_vm.date1=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","error-messages":_vm.errorField.startDate,"label":"Event Start Date (YYYY-MM-DD)","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.startDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "startDate", $$v)},expression:"editedItem.startDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.editedItem.startDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "startDate", $$v)},expression:"editedItem.startDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu1 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu1.save(_vm.date1)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-menu',{ref:"menu2",attrs:{"close-on-content-click":false,"return-value":_vm.date2,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.date2=$event},"update:return-value":function($event){_vm.date2=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","error-messages":_vm.errorField.endDate,"label":"Event End Date (YYYY-MM-DD)","prepend-inner-icon":"mdi-calendar","readonly":""},model:{value:(_vm.editedItem.endDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "endDate", $$v)},expression:"editedItem.endDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.editedItem.endDate),callback:function ($$v) {_vm.$set(_vm.editedItem, "endDate", $$v)},expression:"editedItem.endDate"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu2 = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu2.save(_vm.date2)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"dense":"","filled":"","items":_vm.languages,"item-text":"isoLanguageName","item-value":"isoLanguageCode","label":"Event Language","error-messages":_vm.errorField.language,"menu-props":{
                    bottom: true,
                    offsetY: true,
                  }},model:{value:(_vm.editedItem.language),callback:function ($$v) {_vm.$set(_vm.editedItem, "language", $$v)},expression:"editedItem.language"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"dense":"","filled":"","items":_vm.eventCategoriesData,"label":"Event Category","error-messages":_vm.errorField.eventCategory,"menu-props":{
                    bottom: true,
                    offsetY: true,
                  }},model:{value:(_vm.editedItem.eventCategory),callback:function ($$v) {_vm.$set(_vm.editedItem, "eventCategory", $$v)},expression:"editedItem.eventCategory"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"dense":"","filled":"","items":_vm.statusOptions,"label":"Status","error-messages":_vm.errorField.status,"menu-props":{
                    bottom: true,
                    offsetY: true,
                  }},model:{value:(_vm.editedItem.status),callback:function ($$v) {_vm.$set(_vm.editedItem, "status", $$v)},expression:"editedItem.status"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"text-light",attrs:{"color":"red lighten-1"},on:{"click":function($event){return _vm.closeEditDialog()}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"text-light",attrs:{"color":"green lighten-1"},on:{"click":function($event){return _vm.saveEdit()}}},[_vm._v(" Save ")])],1)],1)],1),_c('HrdcLoader',{attrs:{"loadingDialog":_vm.loadingDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }